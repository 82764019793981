import React from 'react'
import Helmet from 'react-helmet'
import { css } from 'emotion'

import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import GoToTop from '../components/GoToTop';
import './all.scss'
import icon32 from '../img/logo.ico'

const main = css({
  label: 'layout',
  fontSize: '1.07rem',
  '.content': {
    background: '#fff'
  }
})

class TemplateWrapper extends React.Component {
  render() {
    return (
      <section className={`background ${main}`}>
        <Helmet
          title="Chicas al Frente"
          meta={[
            { name: 'description', content: 'Sitio web del colectivo feminista Chicas al Frente' },
            { name: 'keywords', content: 'chicas al frente, feminismo, feminista, sororidad, costa rica, mujeres, activismo, eventos, blog, panelistas, genero, musica, arte' },
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${icon32}` }
          ]}
        />
        <Navbar />
        <div className='container'>
          <div id="layout">
            {this.props.children}
            <Footer />
          </div>
        </div>
        <GoToTop />
      </section>
    )
  }
}


export default TemplateWrapper