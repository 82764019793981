import React from 'react';
import { css } from 'emotion';

const main = css({
  svg: {
    height: '1em'
  },

  '.goto': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    background: '#937EB6',
    height: '30px',
    width: '30px',
    cursor: 'pointer',
    transition: 'background-color .3s, opacity .5s',

    'svg': {
      height: '1.2em'
    },

    '&:hover, :focus': {
      background: '#92bf84'
    }
  },

  '.show': {
    opacity: '1'
  },

  '.hide': {
    opacity: 0
  },

  '@media all and (max-width: 450px)': {
    '.goto': {
      width: '25px',
      height: '25px',
      bottom: '10px',
      right: '10px'
    },

    'svg': {
      height: '1.1em !important'
    },
  }

});


class GoToTop extends React.Component {

  gotoBtn = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if(this.gotoBtn && this.gotoBtn.current)
      this.gotoBtn.current.className = window.scrollY > 500 ? 'goto show' : 'goto hide';
  }

  render() {

    return (
      <div className={main}>
        <div
          className="goto"
          ref={this.gotoBtn}
          onClick={() => setTimeout(() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }, 0)}>
          <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#fff" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
        </div>
      </div>
    )
  }

}

export default GoToTop;